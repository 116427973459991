/**
 * @name: 采购管理-采购员管理接口文件
 * @author:lili
 * @date: 2023-08-08 11:46
 * @description： 采购管理-采购员管理接口文件
 * @update: 2023-08-08 11:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IPersonnel, IPersonnelParams} from "@/apis/purchase/user/types";

/**
 * 查询采购员列表
 * @param params 查询参数
 * @returns
 */
export const personnelQueryApi = (params: IPersonnelParams) => get<IPageRes<IPersonnel[]>>("/admin/personnel/query", params)
/**
 * 创建采购员
 * @param data
 * @returns
 */
export const personnelCreateApi = (data: IPersonnel) => postJ("/admin/personnel/create", data)
/**
 * 编辑采购员
 * @param data
 * @returns
 */
export const personnelModifyApi = (data: IPersonnel) => postJ("/admin/personnel/modify", data)

/**
 * 获取采购员删除
 * @param id
 * @returns
 */
export const personnelRemoveApi = (id: string) => get<IPersonnel>("/admin/personnel/remove/" + id)
/**
 * 获取采购员详情
 * @param id
 * @returns
 */
export const personnelDetailApi = (id: string) => get<IPersonnel>("/admin/personnel/detail/" + id)
